//import { NewPoll } from "./types";
import { StatusCode } from "constants/statuscode";

export enum Status {
  SUCCESS = "success",
  FAILURE = "failure"
}

export interface ErrorResponse extends Error {
  status: Status.FAILURE;
}

export interface HttpException extends ErrorResponse {
  statusCode: StatusCode;
  error: string | null;
}

export interface HttpResponse<T = any> extends HttpException {
  data: T ;
}

export enum Tier {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
}
export enum TierEnum {
  ONE = 1,
  TWO,
  THREE,
}
export type TierKeys = keyof typeof Tier;

export enum Dare {
  ONE = "FREE",
  TWO = "FIVE",
  THREE = "TEN",
}
export enum DareEnum {
  FREE = 1,
  FIVE = 5,
  TEN = 10
}
export type DareKeys = keyof typeof DareEnum;

export interface TierData {
  key: TierKeys;
  ratio: number;
  value: number;
  accumulated: boolean;
  count: number;
}

export interface UserData {
  id: ID;
  role: Role
  username: Username
  displayName: string
  photoURL: string
  balance: Balance
}

export interface OptionData {
  id: ID;
  title: Title;
  creator: ID;
  poll: ID;
  tier: TierEnum[]; // TODO: replaced by TierData
  voteCount: number;
}

export type RolesData = Record<string, string>

export interface PollData {
  id: ID;
  creator: UserData;
  title: Title;
  description: string;
  teaser: string;
  options: OptionData[];
  roles: RolesData;
  category: Category;
  winner: ID | null;
  voteCount: number;
  deadline: Date;
  expired: boolean;
}

export enum TransactionStatusEnum {
  "ACCEPTED" = 30001,
  "REJECTED",
  "PENDING",
  "APPROVED",
  "VOID",
  "BLOCKED",
}
export interface TransactionData {
  poll: PollData;
  option: OptionData;
  payee: UserData;
  payer: UserData;
  tier: TierKeys;
  status: TransactionStatusEnum;
}

export enum ErrorCode {
  OK = "ok",
  CANCELLED = "cancelled",
  UNKNOWN = "unknown",
  INVALID_ARGUMENT = "invalid-argument",
  DEADLINE_EXCEEDED = "deadline-exceeded",
  NOT_FOUND = "not-found",
  ALREADY_EXISTS = "already-exists",
  PERMISSION_DENIED = "permission-denied",
  UNAUTHENTICATED = "unauthenticated",
  RESOURCE_EXHAUSTED = "resource-exhausted",
  FAILED_PRECONDITION = "failed-precondition",
  ABORTED = "aborted",
  OUT_OF_RANGE = "out-of-range",
  UNIMPLEMENTED = "unimplemented",
  INTERNAL = "internal",
  UNAVAILABLE = "unavailable",
  DATA_LOSS = "data-loss",
}
export enum ErrorMessage {
  OK = "ok",
  CANCELLED = "The operation was cancelled",
  UNKNOWN = "Unknown error or an error from a different error domain",
  INVALID_ARGUMENT = "Invalid argument",
  DEADLINE_EXCEEDED = "Deadline expired before operation could complete",
  NOT_FOUND = "Some requested document was not found",
  ALREADY_EXISTS = "Some document that we attempted to create already exists",
  PERMISSION_DENIED = "You don’t have the permission to execute the operation",
  UNAUTHENTICATED = "You are not authenticated",
  RESOURCE_EXHAUSTED = "Some resource has been exhausted",
  FAILED_PRECONDITION = "Operation was rejected because the system is not in a state required for the operation’s execution",
  ABORTED = "The operation was aborted, typically due to a concurrency issue like transaction aborts, etc.",
  OUT_OF_RANGE = "Operation was attempted past the valid range",
  UNIMPLEMENTED = "Operation is not implemented or not supported/enabled",
  INTERNAL = "Internal errors",
  UNAVAILABLE = "The service is currently unavailable",
  DATA_LOSS = "Unrecoverable data loss or corruption",
}
export type ID = string;
export type Title = string;
export type TimeLimit = Date;
export type Balance = number;
export type PaymentHistory = {
  pollId: ID;
  optionId: ID;
};
export type Username = string;
export type UsedFreeVote = boolean;
export type TierVoteCount = number;
export type VoteCount = number;
export type Budget = number;
// export enum Tier {
//   ONE = 1,
//   TWO,
//   THREE,
// }
export const VoteTierMap = {
  [Tier.ONE]: 1,
  [Tier.TWO]: 5,
  [Tier.THREE]: 10,
} as const;
export const RATIO = 0;
export const BalanceTierMap = {
  [Tier.ONE]: 0,
  [Tier.TWO]: 10,
  [Tier.THREE]: 10,
} as const;
export type VoteHistory = {
  [key in Tier]: number;
};
export interface VoteInfo {
  user: User;
  voteHistory: VoteHistory;
  usedFreeVote: UsedFreeVote;
}
export interface VoteInfoDB {
  userId: ID;
  voteHistory: VoteHistory;
  usedFreeVote: UsedFreeVote;
}
// Option
export interface NewOption {
  title: string;
  description?: string;
  budget: Budget;
}
export interface Option {
  id: ID;
  title: string;
  description?: string;
  votes: VoteInfo[];
  voteCount: VoteCount;
  budget: Budget;
}
export interface OptionDB {
  id: ID;
  title: string;
  description?: string;
  votes: VoteInfoDB[];
  voteCount: VoteCount;
  budget: Budget;
}
export type Category =
  | "travel"
  | "cultural"
  | "foodie"
  | "fitness"
  | "entertainment"
  | "photography"
  | "lifestyle"
  | "sports"
  | "fashion"
  | "art"
  | "beauty"
  | "pets"
  | "nature"
  | "tutorial";
// Post
export interface NewPost {
  url: string;
  description: string;
}
export interface Post {
  id?: ID;
  url: string;
  description: string;
}
export interface PostDB {
  id?: ID;
  url: string;
  description: string;
}
// Poll
export interface NewPoll {
  creatorId: ID;
  title: Title;
  description: string;
  options: NewOption[];
  categories: Category[];
  timeLimit: TimeLimit;
}
export interface Poll {
  id: ID;
  creator: User;
  title: Title;
  onGoing: Boolean;
  description: string;
  options: Option[];
  categories: Category[];
  winner: Option | null;
  timeLimit: TimeLimit;
  post: Post | null;
}
export interface PollDB {
  id: ID;
  creatorId: ID;
  title: Title;
  onGoing: Boolean;
  description: string;
  categories: Category[];
  winner: Option | null;
  timeLimit: TimeLimit;
  postId: ID | null;
}
export type EditablePollFields = Partial<Omit<Poll, "options" | "timeLimit">>;
export enum Role {
  USER = 'USER', // or visitor
  ADMIN = 'ADMIN',
  CREATOR = 'CREATOR',
  PLAYER = 'PLAYER'
}
export type RoleKeys = keyof Role;

export enum PollRole {
  OWNER = 'OWNER',
  READER = "READER",
  WRITER = "WRITER", // dare to owner
  COMMENTER = "COMMENTER",
  VOTER = "VOTER",
}
export type PollRoleKeys = keyof PollRole;

export interface User {
  id: ID;
  username: Username;
  displayName: string;
  balance: Balance;
  role: Role;
  // paymentHistory: PaymentHistory[];
}
// callable functions
export interface CreatePollParams {
  poll: NewPoll;
}
export interface VoteParam {
  pollId: ID;
  optionId: ID;
  tier: Tier;
}
export interface EditPollParam {
  pollId: ID;
  data: EditablePollFields;
}
export interface GetPostParam {
  postId: ID;
}
export interface CreatePostParam {
  pollId: ID;
  post: NewPost;
}
export interface GetUserParam {
  userId: ID;
}

export type Limit = number;
export type FieldPath = string;
export type StartAt = FieldPath;
export interface AdminGetUsersParam {
  limit?: Limit;
  after?: StartAt;
}

export interface AdminUpdateBalanceParam {
  userId: ID;
  newBalance: Balance;
}
// response
export interface APIResponse {
  error: Error;
}
export interface GetPollResponse {
  data: {
    polls: Poll[];
  };
}
export interface CreatePollResponse {
  data: {
    pollId: ID;
  };
}
export interface VoteResponse {
  data: {
    message: string;
  };
}
export interface EditPollResponse {
  data: {
    poll?: Poll;
  };
}
export interface CreatePostResponse {
  data: {
    postId: ID;
  };
}
export interface GetUserResponse {
  data: {
    user: User;
  };
}

export interface Paging {
  next: ID | null;
  hasMore: boolean;
}
export interface AdminGetUsersResponse {
  data: {
    users: User[];
    paging: Paging;
  };
}

export interface AdminUpdateBalanceResponse {
  data: {
    message: string;
  };
}

// callable functions
export type GetPolls = () => Promise<GetPollResponse>;
export type CreatePoll = ({}: CreatePollParams) => Promise<CreatePollResponse>;
export type Vote = ({}: VoteParam) => Promise<VoteResponse>;
export type GetUser = ({}: GetUserParam) => Promise<GetUserResponse>;
export type AdminGetUser =
  ({}: AdminGetUsersParam) => Promise<AdminGetUsersResponse>;
export type AdminUpdateBalance =
  ({}: AdminUpdateBalanceParam) => Promise<AdminUpdateBalanceResponse>;
export type CreatePost = ({}: CreatePostParam) => Promise<CreatePostResponse>;
