import { Category } from "types/api";

export const POLLFORM_SET_FORM_VALUE_TYPE = "POLLFORM:SET_FORM_VALUE_TYPE";

type SET_FORM_ACTION = {
  type: typeof POLLFORM_SET_FORM_VALUE_TYPE;
  payload: Partial<{
    deadline: number;
    title: string;
    reward?: string;
    category: Category | "";
    teaser: FormData | null;
    image: any;
    options: string[];
  }>;
};

export type IPollFormActions =
  | SET_FORM_ACTION;