import { useCallback, useEffect, useMemo, useState } from "react";

import firebase from 'firebase'
import { UserContext } from "types/context";

import { USER_SET_DATA_TYPE } from "../../actions/global";
import { auth, db } from "../../utils/firebase/configs";
import useGlobalContext from "../common/useGlobalContext";

interface UserListenerHookProps {
  onCompleted: (data: firebase.auth.UserCredential['user']) => void;
}

const useUserListener = (props?: UserListenerHookProps) => {
  const { globalState, globalDispatch } = useGlobalContext();
  const [loading, setLoading] = useState(true);

  const listener = useCallback(async (user) => {
    try {
      if (!user) return;
      setLoading(true);
      db.collection("users")
      .doc(user!.uid)
      .onSnapshot((snap) => {
        const data = snap.data()!;

        if (data) {
          globalDispatch({
            type: USER_SET_DATA_TYPE,
            payload: {
              id: user.uid,
              role: data.role,
              username: data.username,
              balance: data.balance,
              displayName: data.displayName,
              photoURL: data.photoURL
            } as UserContext,
          });
          props?.onCompleted && props.onCompleted(user);
        }
      });

     // const response = await getUser(user!.uid);

      // globalDispatch({
      //   type: USER_SET_DATA_TYPE,
      //   payload: response!.data.data.balance
      // });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setLoading, globalDispatch])


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(listener);

    return () => {
      unsubscribe();
    };
  }, []);

  return useMemo(
    () => ({
      user: globalState.user,
      loading,
    }),
    [loading, globalState.user]
  );
};

export default useUserListener;
