import { PollResponse } from "api/poll";
import { TierResponse } from "api/tier";
import { schema } from "normalizr";

import { Balance, Category, ID, Role, TierKeys, Title, Username } from "./api";
import { IPlayerInfo } from "./common";

interface User {
  id: ID;
  username: Username;
  balance: Balance;
  role: Role;
}

interface Option {
  id: ID;
  title: Title;
  creator: ID;
  poll: ID;
  tier: TierKeys[];
  voteCount: number;
}

interface Poll {
  id: ID;
  creator: User;
  title: Title;
  description: string;
  options: ID[];
  category: Category;
  winner: ID | null;
  voteCount: number;
  deadline: Date;
  expired: boolean;
}

interface DareMe {
  deadline: number;
  title: string;
  reward: string;
  category: Category | "";
  teaser: string;
  options: string[];
}

interface NewPoll {
  deadline: number;
  title: string;
  // reward: string;
  category: Category | "";
  teaser: FormData | null;
  image: any;
  options: string[];
}

export interface NormalizedData<T> {
  [uuid: string]: T;
}

export interface NormalizedResult<T extends Poll | Option | User | DareMe> {
  result: any;
  entities: schema.Entity<T>;
}

export enum IGlobalStateKey {
  USER = "user",
  POLL = "polls",
  SYSTEM = "system",
  TIER = "tier",
  DAREME = "dareMe",
}

interface TierState extends TierResponse {
  displayName: string;
}

export type TierContext = TierState[];

export interface UserContext extends IPlayerInfo {}

export interface NormalizedPoll {
  result: any;
  entities: {
    creator: NormalizedData<User>;
    options: NormalizedData<Option>;
    poll: NormalizedData<Poll>;
  };
}

export type PollContext = PollResponse[];

export interface DareMeContext extends DareMe {}

export interface SystemContext {
  loading: boolean;
}

export interface IGlobalState {
  [IGlobalStateKey.TIER]: TierContext;
  [IGlobalStateKey.USER]: UserContext;
  [IGlobalStateKey.POLL]: PollContext;
  [IGlobalStateKey.SYSTEM]: SystemContext;
}

export const initialState: IGlobalState = {
  [IGlobalStateKey.TIER]: [],
  [IGlobalStateKey.USER]: {
    id: "",
    username: null,
    displayName: "You",
    role: Role.USER,
    photoURL: "",
    balance: 0,
    paymentHistory: [],
  },
  [IGlobalStateKey.POLL]: [],
  [IGlobalStateKey.SYSTEM]: {
    loading: false,
  },
};

// 
export interface NewPollContext extends NewPoll {
}

export type IPollFormState = NewPollContext;

export const initialPollFormState: IPollFormState = {
  deadline: 0,
  title: "",
  // reward: "",
  category: "",
  teaser: null,
  image: "",
  options: ["", ""]
}
